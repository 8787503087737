<template>
  <div class="chat-tip">
    <div class="box">
      <div class="task" v-show="showTask">
        <img src="../../assets/img/chatTip.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showTask: false
    }
  },
  created() {
    if (this.isIos()) {
      window.location.href = this.$route.query.ios;
    } else if (this.isAndroid()) {
      if (this.isChat()) {
        this.showTask = true;
      } else {
        if (this.$route.query.android) {
          if (this.$route.query.android.indexOf('http') == -1) {
            window.location.href = 'https://cos.hyfutures.com/' + this.$route.query.android
          } else {
            window.location.href = this.$route.query.android
          }
        } else {
          window.location.href = this.$route.query.ios;
        }
      }
    }
  },
  methods: {
    isChat() {
      var u = navigator.userAgent.toLowerCase();
      if (u.indexOf('micromessenger') != -1) {
        console.log('chat');
        return true;
      } else {
        console.log('no chat');
        return false;
      }
    },
    isAndroid() {
      var u = navigator.userAgent;
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        console.log('and');
        return true;
      }
      console.log('no and');
      return false;
    },
    isIos() {
      var u = navigator.userAgent;
      if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
        console.log('ios');
        return true;
      }
      console.log('no ios');
      return false;
    }
  }
}
</script>
<style scoped>
.box {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.task {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.task img {
  width: 100%;
  height: auto;
  margin-top: 10px;
}
</style>
